<template>

    <div class="w-full h-full px-3 py-4" :class="'bg-body-'+mode">

        <div class="h-full w-full flex flex-col mb-6 overflow-auto">

            <All model="Companytops" :immediate="true" :query="query" :data.sync="datatops" v-slot="{data,loading}">

                <div v-if="!loading" class="h-auto">

                    <div v-for="(el,index) in data.table" :key="index" class="h-auto p-2 flex flex-col mb-2 shadow_box rounded-lg" :class="'bg-box-'+mode">
                        <div class="h-6 flex flex-row justify-start items-center px-2">
                            <span class="text-sm font-semibold" :class="'text-dfont-'+mode">{{ el.name }}</span>
                        </div>
                        <div class="h-auto flex flex-wrap">
                            <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{translate(topindicatorname)}}<sup v-if="topindicatorname == 'Metros'">2</sup></span>
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{el.value | reduceBigNumbers()}}<span v-html="unit"></span></span>
                            </div>
                            <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{$t('growth')}}</span>
                                <span class="text-xs font-medium" :class="{'text-green': el.growth > 0, 'text-red': el.growth <= 0}"><span v-if="el.growth>0">+</span>{{el.growth | reduceBigNumbers()}}<span v-html="unit2"></span></span>
                            </div>
                            <div v-if="el.weighing != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{$t('weight')}}</span>
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode"><span v-if="el.weighing>0 && topindicator > 1">+</span>{{el.weighing | reduceBigNumbers()}}<span v-html="unit2"></span></span>
                            </div>
                            <div v-if="el.groupValue != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">vs {{$t('group')}}</span>
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode"><span v-if="el.groupValue>0 && topindicator > 1">+</span>{{el.groupValue | reduceBigNumbers()}}<span v-html="unit2"></span></span>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div v-else class="h-20 relative">
                    <loader :loading="loading" />
                </div>

            </All>

        </div>

    </div>

</template>

<script>
import basiclinechart from '@/components/basiclinechart.vue';
import { All } from '@/api/components';
import { state, actions } from '@/store';
import loader from '@/components/loader.vue';

export default {
    components:{
        basiclinechart,
        All,
        loader
    },
    data() {
        return {
            datatops: null
        }
    },
    methods: {
        translate(word) {
            switch (word) {
                case 'Paises':
                    return this.$t("countries")
                    break;
                case 'Todos los continentes':
                    return this.$t("allContinents")
                    break;
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                case 'Continentes':
                    return this.$t("continents")
                    break;
                case 'Todos los paises':
                    return this.$t("allCountries")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'EUROPA':
                    return this.$t("europe")
                    break;
                case 'AMERICA CENTRAL':
                    return this.$t("centralOfAmerica")
                    break;
                case 'AMERICA SUR':
                    return this.$t("southOfAmerica")
                    break;
                case 'AMERICA NORTE':
                    return this.$t("northOfAmerica")
                    break;
                case 'ASIA':
                    return this.$t("asia")
                    break;
                case 'OCEANIA':
                    return this.$t("oceania")
                    break;
                case 'AFRICA':
                    return this.$t("africa")
                    break;
                case 'meters':
                    return this.$t("meters")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                case 'Calidad':
                    return this.$t("quality")
                    break;
                default:
                    return word;
            }
        },
        toFilters() {
            actions.setLastPage(this.$route.name)
            this.$router.push('filterstop')
        }
    },
    computed: {
        monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
        },
        mode(){
            return state.mode
        },
        query() {
            return {
                period: state.topPeriod,
                Dimension: state.topIndicator,
                Group: state.topDimension,
                Place: state.topTypePlace,
                PlaceId: state.topPlace,
                Company: state.idCompany,
                IndustrialGroup: state.industrialGroup,
                ClientType: state.typeclienttops,
                month: state.month
            }
        },
        periodo() {
            switch (state.topPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        unit() {
            let unit = ' €'
            if (state.topIndicator == 1) { unit = ' m<sup>2</sup>' }
            if (state.topIndicator == 3) { unit = ' %' }
            if (state.topIndicator == 2) { unit = '€/m<sup>2</sup>' }
            return unit
        },
        unit2() {
            let unit = ' %'
            if (state.topIndicator == 2) { unit = '€/m<sup>2</sup>' }
            if (state.topIndicator == 3) { unit = '' }
            return unit
        },
        topdimension() { return state.topDimension },
        topplace() { return state.topPlace },
        topindicator() { return state.topIndicator },
        topdimensionname() { return state.topDimensionName },
        topindicatorname() {
            if (state.topIndicatorName != 'Metros cuadrados') {
                return state.topIndicatorName }
            else { return this.$t("meters") }
        },
        topplacename() { return state.topPlaceName },
        nameCompany() { return state.companySelected },
        clienttype() { return state.typeclienttops }
    },
    mounted() {
        if (state.topDimension == 'Company') {
            actions.setTopDimension('Country')
            actions.setTopDimensionName('Paises')
        }
    }
}
</script>
